import { changeToneMoodSchema } from "@reactive-resume/dto";
import { createOpenAiResponse } from "./client";
import { z } from "nestjs-zod/z";

type Mood = z.infer<typeof changeToneMoodSchema>;

export const changeTone = async (text: string, mood: Mood) => {
  return createOpenAiResponse({
    type: "change-tone",
    data: { text, mood },
  });
};
