import { CircleNotch, PauseCircle, PlayCircle } from "@phosphor-icons/react";
import { AlertDialog, AlertDialogContent } from "@reactive-resume/ui";
import { cn } from "@reactive-resume/utils";
import { forwardRef, useEffect, useRef, useState } from "react";

const VIDEO_URL = "https://dqupf4962yamn.cloudfront.net/cv_builder/assets/explainer.mp4";

export const ExplainerVideo = ({ show, onClose }: { show: boolean; onClose: () => void }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const ref = useRef<HTMLVideoElement>(null);

  const onLoaded = () => {
    setIsLoaded(true);
    setIsPlaying(true);
  };

  const onUnload = () => {
    setIsPlaying(false);
    setIsLoaded(false);
  };

  const onContainerClick = () => {
    isPlaying ? pause() : play();
  };

  const pause = () => {
    if (!ref.current) {
      return;
    }

    ref.current.pause();
    setIsPlaying(false);
  };

  const play = () => {
    if (!ref.current) {
      return;
    }

    ref.current.play();
    setIsPlaying(true);
  };

  return (
    <AlertDialog open={show} onOpenChange={onClose}>
      <AlertDialogContent
        className="max-w-screen-lg "
        overlayProps={{
          className: "bg-black/50",
          onClick: onClose,
        }}
      >
        <div
          className={cn(
            "absolute w-full h-full flex items-center justify-center",
            "cursor-pointer z-10 group/item",
          )}
          onClick={onContainerClick}
        >
          {!isLoaded && (
            <CircleNotch size={64} weight="thin" opacity={0.5} className="animate-spin" />
          )}
          {isLoaded &&
            (isPlaying ? (
              <PauseCircle
                className="invisible group-hover/item:visible"
                size={64}
                weight="fill"
                opacity={0.5}
              />
            ) : (
              <PlayCircle size={64} weight="fill" opacity={0.5} />
            ))}
        </div>
        <Video
          src={VIDEO_URL}
          autoPlay
          loop
          ref={ref}
          onLoad={onLoaded}
          onUnload={onUnload}
          className="aspect-video w-full h-full"
        />
      </AlertDialogContent>
    </AlertDialog>
  );
};

const Video = forwardRef<
  HTMLVideoElement,
  React.ComponentPropsWithoutRef<"video"> & {
    onLoad: () => void;
    onUnload: () => void;
  }
>(({ onLoad, onUnload, ...props }, ref) => {
  useEffect(() => {
    if (!ref) {
      return;
    }

    if ("current" in ref && ref.current) {
      ref.current.addEventListener("loadeddata", onLoad);

      return () => {
        onUnload();
        ref.current?.removeEventListener("loadeddata", onLoad);
      };
    }
  }, [ref]);

  return <video ref={ref} {...props} />;
});
