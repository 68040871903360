import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useEffect } from "react";

import { axios } from "@/client/libs/axios";
import { useCampusStore } from "@/client/stores/campus";
import { CampusUserMetadataDto } from "@reactive-resume/dto";

export const fetchUserMetadata = async () => {
  const response = await axios.get<CampusUserMetadataDto, AxiosResponse<CampusUserMetadataDto>>(
    "/campus-app/user/metadata",
  );

  return response.data;
};

export const useCampusUserMetadata = () => {
  const setUserMetadata = useCampusStore((state) => state.setMetadata);

  const {
    error,
    isPending: loading,
    data: metadata,
  } = useQuery({
    queryKey: ["campus-user-metadata"],
    queryFn: fetchUserMetadata,
  });

  useEffect(() => {
    setUserMetadata(metadata ? metadata : { enableResumeProfilePicture: false });
  }, [metadata, setUserMetadata]);

  return { metadata, loading, error };
};
