import { ResumeDto } from "@reactive-resume/dto";
import { useMutation } from "@tanstack/react-query";

import { axios } from "@/client/libs/axios";
import { queryClient } from "@/client/libs/query-client";
import { t } from "@lingui/macro";
import { useToast } from "@/client/hooks/use-toast";
import { useDialog } from "@/client/stores/dialog";
import { SimpleDialogItem } from "@/client/components/simple-dialog";

type DoneResumeArgs = {
  id: string;
  isDone: boolean;
};

export const setResumeDoneStatus = async ({ id, isDone }: DoneResumeArgs) => {
  const response = await axios.patch(`/resume/${id}/done`, { isDone });

  queryClient.setQueryData<ResumeDto>(["resume", { id: response.data.id }], response.data);

  queryClient.setQueryData<ResumeDto[]>(["resumes"], (cache) => {
    if (!cache) return [response.data];
    return cache.map((resume) => {
      if (resume.id === response.data.id) return response.data;
      return resume;
    });
  });

  return response.data;
};

export const useUpdateDoneResume = () => {
  const { toast } = useToast();
  const { open: openMarkAsDone } = useDialog<SimpleDialogItem>("simple");
  const {
    error,
    isPending: loading,
    mutateAsync: setResumeDoneStatusFn,
  } = useMutation({
    mutationFn: setResumeDoneStatus,
  });

  const toggleResumeDoneStatus = async (resume: ResumeDto) => {
    const id = resume.id;
    const isMarkedAsDone = !resume.done;
    const name = resume.title;
    await setResumeDoneStatusFn({ id, isDone: isMarkedAsDone });
    if (isMarkedAsDone) {
      openMarkAsDone("update", {
        id: "simple",
        item: {
          title: t`${name} marked as done`,
          description: t`Awesome! 🎉 This resume is ready for review. Don't forget to share it with your Career success advisor for valuable feedback.`,
          action: {
            variant: "primary",
            text: t`Done`,
          },
        },
      });
    } else {
      toast({
        variant: "success",
        title: t`Done status cleared`,
        description: t`Remember to mark this resume as done before you share it again with your Career Success Advisor`,
      });
    }
  };

  return { toggleResumeDoneStatus, loading, error };
};
