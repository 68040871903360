import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { campusUrlWithPath } from "../constants/campus";

export const CampusProvider = ({ children }: { children: React.ReactNode }) => {
  const [params, setParams] = useSearchParams();

  useEffect(() => {
    if (params.get("campusBackTo")) {
      const campusBackTo = params.get("campusBackTo") as string;
      localStorage.setItem("campusBackTo", campusBackTo);
      params.delete("campusBackTo");
      setParams(params);
    }
  }, [params, setParams]);

  return <>{children}</>;
};

export const getCampusBackToUrl = () => {
  const campusBackTo = localStorage.getItem("campusBackTo") || "/job-search";

  return campusUrlWithPath(campusBackTo);
};
