import { ResumeDto, UrlDto } from "@reactive-resume/dto";
import { useQuery } from "@tanstack/react-query";

import { RESUME_PREVIEW_KEY } from "@/client/constants/query-keys";
import { axios } from "@/client/libs/axios";
import { useEffect } from "react";

export const previewResume = async (data: { id: string }) => {
  const response = await axios.get<UrlDto>(`/resume/print/${data.id}/preview`);

  return response.data;
};

export const useResumePreview = (id: string, resumeUpdatedAt: ResumeDto["updatedAt"]) => {
  const { error, isPending, data, dataUpdatedAt, refetch, isFetching } = useQuery({
    queryKey: [RESUME_PREVIEW_KEY, { id }],
    queryFn: () => previewResume({ id }),
  });

  useEffect(() => {
    const previewLoadedTime = new Date(dataUpdatedAt).getTime();
    const resumeUpdatedTime = new Date(resumeUpdatedAt).getTime();
    const oneMinuteAgo = Date.now() - 1000 * 60;
    const isUrlExpired = previewLoadedTime < oneMinuteAgo;
    const isResumeUpdatedLately = previewLoadedTime < resumeUpdatedTime;

    if (isResumeUpdatedLately || isUrlExpired) {
      refetch();
    }
  }, [resumeUpdatedAt, dataUpdatedAt, refetch]);

  const loading = isPending || isFetching;

  return { url: data?.url, loading, error };
};
