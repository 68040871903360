import { t } from "@lingui/macro";
import { Plus } from "@phosphor-icons/react";
import { KeyboardShortcut } from "@reactive-resume/ui";
import { cn } from "@reactive-resume/utils";

import { useDialog } from "@/client/stores/dialog";

import { BaseCard } from "./base-card";

export const CreateResumeCard = () => {
  const { open } = useDialog("resume");

  return (
    <BaseCard onClick={() => open("create")}>
      <Plus size={64} weight="thin" />
      <h4 className="font-medium">{t`Create a new resume`}</h4>
    </BaseCard>
  );
};
