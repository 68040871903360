import { cn } from "@reactive-resume/utils";

type Props = {
  size?: number;
  className?: string;
  withText?: boolean;
};

export const Icon = ({ size = 32, className, withText = false }: Props) => {
  const src = withText ? "/icon/masterschool-logo.svg" : "/logo/dark.svg";

  return (
    <img
      src={src}
      width={size}
      height={size}
      alt="Reactive Resume"
      className={cn("rounded-sm", className)}
    />
  );
};
