import { t } from "@lingui/macro";
import {
  Button,
  ButtonProps,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@reactive-resume/ui";
import { useDialog } from "../stores/dialog";

export type SimpleDialogItem = {
  title: string | undefined;
  description: string | undefined;
  action: {
    variant: ButtonProps["variant"];
    text: string | undefined;
  };
};

export const SimpleDialog = () => {
  const { isOpen, mode, payload, close } = useDialog<SimpleDialogItem>("simple");
  const { item } = payload || {};
  const {
    title = "",
    description = "",
    action = {
      variant: "info",
      text: t`Action`,
    },
  } = item || {};

  return (
    <Dialog open={isOpen} onOpenChange={close}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>

        <DialogFooter>
          <Button variant={action.variant} onClick={close}>
            {action.text}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
