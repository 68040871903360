/* eslint-disable lingui/text-restrictions */

import { createOpenAiResponse } from "./client";

export const improveWriting = async (text: string) => {
  const result = await createOpenAiResponse({
    type: "improve-writing",
    data: { text },
  });

  return result;
};
