import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { redirectToCampusAuth } from "@/client/services/auth";
import { useUser } from "@/client/services/user";
import { useCampusUserMetadata } from "@/client/services/campus";

export const AuthGuard = () => {
  const { user, loading } = useUser();
  const { loading: loadingCampusUserMetadata, metadata: campusUserMetadata } =
    useCampusUserMetadata();

  useEffect(() => {
    if (!loading && !user) {
      console.log("Redirecting to campus auth url");
      redirectToCampusAuth();
    }
  }, [loading, user]);

  useEffect(() => {
    if (!loadingCampusUserMetadata && !campusUserMetadata) {
      console.warn("Campus user metadata not found");
    }
  }, [loadingCampusUserMetadata, campusUserMetadata]);

  if (loading || !user || loadingCampusUserMetadata) return null;

  return <Outlet />;
};
