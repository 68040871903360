import { CampusUserMetadataDto } from "@reactive-resume/dto";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface CampusStore {
  metadata: CampusUserMetadataDto;
  setMetadata: (metadata: CampusUserMetadataDto) => void;
}

export const useCampusStore = create<CampusStore>()(
  persist(
    (set) => ({
      metadata: {
        enableResumeProfilePicture: false,
      },
      setMetadata: (metadata) => set({ metadata }),
    }),
    { name: "campus" },
  ),
);
