import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { axios } from "@/client/libs/axios";

export const deleteImage = (name: string) => {
  return axios.delete<
    string,
    AxiosResponse<string>,
    {
      name: string;
    }
  >("/storage/image", {
    data: {
      name,
    },
  });
};

export const useDeleteResumeProfileImage = () => {
  const {
    error,
    isPending: loading,
    mutateAsync: deleteImageFn,
  } = useMutation({
    mutationFn: deleteImage,
  });

  return { deleteImage: deleteImageFn, loading, error };
};
