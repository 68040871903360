import { createZodDto } from "nestjs-zod/dto";
import { z } from "nestjs-zod/z";
export const createOpenAIRequestSchema = z.object({
  text: z.string(),
});

export const changeToneMoodSchema = z.enum(["casual", "professional", "confident", "friendly"]);
export const createOpenAIRequestWithMoodSchema = createOpenAIRequestSchema.extend({
  mood: changeToneMoodSchema,
});

export class CreateOpenAIRequestDto extends createZodDto(createOpenAIRequestSchema) {}
export class CreateOpenAIRequestWithMoodDto extends createZodDto(
  createOpenAIRequestWithMoodSchema,
) {}
