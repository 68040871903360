import {
  CreateOpenAIRequestDto,
  CreateOpenAIRequestWithMoodDto,
  OpenAiResponse,
} from "@reactive-resume/dto";
import axios, { AxiosResponse } from "axios";

export const createOpenAiResponse = async (request: CreateOpenAIRequest) => {
  const response = await axios.post<
    OpenAiResponse,
    AxiosResponse<OpenAiResponse>,
    CreateOpenAIRequestDto | CreateOpenAIRequestWithMoodDto
  >(`api/ai/${request.type}`, request.data);

  return response.data.text;
};

type CreateOpenAIRequest =
  | {
      type: "improve-writing" | "fix-grammar";
      data: CreateOpenAIRequestDto;
    }
  | {
      type: "change-tone";
      data: CreateOpenAIRequestWithMoodDto;
    };
